export const TABLE_NAMES = {
  INTAKE_LIST: 'intake',
  FILLING_LIST: 'filling',
  BILLING_LIST: 'billing',
  VERIFICATION_LIST: 'verification',
  READY_LIST: 'ready',
  USER_LIST: 'user-listing',
  USER_TYPE_LIST: 'user-type-listing',
  UPCOMING_REFILLS_LIST: 'upcoming-refill-list',
  COMPLETED_LIST: 'completed',
  TRANSACTION_HISTORY: 'transaction-history',
  SIG_LIST: 'sig-list',
  PRICE_PLAN_LIST: 'price-plan-list',
  PRESCRIBER_LIST: 'prescriber-list',
  PATIENT_LIST: 'patient-list',
  INSURANCE_LIST: 'insurance-list',
  FACILITY_LIST: 'facility-list',
  DRUG_LIST: 'drug-list',
  PRESCRIPTION_LIST: 'prescription-list',
  DELIVERY_LIST: 'delivery-list',
  WHOLESALER_INVOICE_LIST: 'wholesaler-invoice-list',
  TAG_LIST: 'tag-list',
  LABEL_LIST: 'label-list',
  PHARMACY_LIST: 'pharmacy-list',
  CONTRACT_LIST: 'contract-list',
  CURRENT_INVENTORY_LIST: 'current-inventory-list',
  RECYCLE_BIN_LIST: 'recycle-bin-list',
  LABEL_TEMPLATE_LIST: 'label-template-list',
  PATIENT_RX_HISTORY: 'patient-rx-history',
  PRESCRIBER_RX_HISTORY: 'prescriber-rx-history',
  CHANGE_HISTORY: 'change-history',
  DRUG_PICKER: 'drug-picker',
  FDB_DRUG_PICKER: 'fdb-drug-picker',
  PRESCRIBER_PICKER: 'prescriber-picker',
  PATIENT_PICKER: 'patient-picker',
  INSURANCE_PICKER: 'insurance-picker',
  FACILITY_PICKER: 'facility-picker',
  PRICE_PLAN_PICKER: 'price-plan-picker',
  CONTRACT_PICKER: 'contract-picker',
  PHARMACY_PICKER: 'pharmacy-picker',
  ERX_MESSAGES: 'erx-messages',
  CANNED_REPORTS: 'canned-reports',
  COMPLIANCE_REPORTS: 'compliance-reports',
  PRINTER_LIST: 'printer-list',
  VIEW_REPORT_DATA: 'view-report-data',
  PAYER_SHEET: 'payer-sheet',
  PRESCRIBER_DIRECTORY_COMPARISON: 'prescriber-directory-comparison',
  RX_LIST: 'rx-list',
  POS_ORDER_LIST: 'pos-order-list',
  PATIENT_DELIVERY_LIST: 'patient-delivery-list',
  POS_PHARMACY_CATALOGUE_ITEM_LIST: 'pharmacy-catalogue-item-list',
  POS_CATALOGUE_ITEM_LIST: 'catalogue-item-list',
  POS_ORDER_PAYMENT_LIST: 'pos-order-payment-list',
  POS_ORDER_PAYMENT_REFUND_LIST: 'pos-order-payment-refund-list',
  POS_PRODUCT_CATALOGUE_MAPPING_LIST: 'pos-product-category-mapping-list',
  DELIVERY_QUEUE_LIST: 'delivery-queue-list'
} as const;
