import { useAuth0 } from '@auth0/auth0-react';
import { AUTH_CONFIG } from '@linx-ui/shared/constants';

export const useLogout = () => {
  const { logout } = useAuth0();

  const handleLogout = async () => {
    await logout({ logoutParams: { returnTo: AUTH_CONFIG.redirectURL } });
  };

  return handleLogout;
};
