import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, deleteDoc, doc, onSnapshot, setDoc, type QuerySnapshot } from 'firebase/firestore';
import moment from 'moment-timezone';
import { usePermissions } from '@linx-ui/shared/core';
import { db } from '@linx-ui/shared/core/firebase/app';
import { UserAvatar } from '../../userAvatar';
import { ProfileCnt, StyledBadge } from './ActiveUserProfiles.styled';

const TIMER_INTERVAL = 1; // In minutes

export const ActiveUserProfiles = () => {
  const location = useLocation();
  const [timer, setTimer] = useState<number>(0);
  const [snapshot, setSnapshot] = useState<QuerySnapshot>();
  const { userName, userId } = usePermissions();
  const userViewsCollectionName = location.pathname.replaceAll('/', '\\');
  const pageViewInterval = useRef<NodeJS.Timeout>();

  const userProfiles = useMemo(
    () =>
      snapshot?.docs
        .filter(
          (doc) =>
            doc.id !== userId &&
            doc.data().lastViewed &&
            moment(doc.data().lastViewed)
              .local()
              .isAfter(moment().subtract(TIMER_INTERVAL + 1, 'minutes'))
        )
        .map((doc) => (
          <StyledBadge
            key={doc.id}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
          >
            <UserAvatar size={4} userName={doc.data().userName} />
          </StyledBadge>
        )) || [],
    [snapshot, userId, timer]
  );

  // Update user list
  useEffect(() => {
    db && userViewsCollectionName && onSnapshot(collection(db, userViewsCollectionName), setSnapshot);
  }, [userViewsCollectionName]);

  // Push user to page view collection
  useEffect(() => {
    const docRef = userId && db && userViewsCollectionName && doc(db, userViewsCollectionName, userId);
    if (docRef) {
      void setDoc(docRef, {
        userId,
        userName,
        lastViewed: moment().utc().format()
      });
    }
    return () => {
      if (docRef) void deleteDoc(docRef);
    };
  }, [userId, userViewsCollectionName, userName, timer]);

  useEffect(() => {
    pageViewInterval.current = setInterval(() => setTimer((prev) => prev + 1), TIMER_INTERVAL * 1000 * 60);
    return () => {
      pageViewInterval.current && clearInterval(pageViewInterval.current);
    };
  }, []);

  return userProfiles?.length > 0 ? <ProfileCnt>{userProfiles}</ProfileCnt> : null;
};
